import React, { Component } from "react";
import { enquireScreen } from "enquire-js";
import { injectIntl } from "gatsby-plugin-intl";

import {
  Banner03DataSource,
  // Feature102DataSource,
  OCR01DataSource
  // OCR02DataSource
} from "../../../data/xu_ly_hinh_anh.data";
import { ContactFormDataSource } from "../../../data/home.data";
import loadable from "@loadable/component";

const Layout = loadable(() => import("../../../components/Layout"));
const SEO = loadable(() => import("../../../components/SEO"));
const Banner = loadable(() => import("../../../components/Banner"));

const ContactForm = loadable(() =>
  import("../../../components/Home/ContactForm")
);
// const Feature102 = loadable(() => import("../../../components/OCR/Feature102"));
const OCR01 = loadable(() => import("../../../components/OCR/OCR01"));
// const OCR02 = loadable(() => import("../../../components/OCR/OCR02"));

let isMobile;
enquireScreen(b => {
  isMobile = b;
});

class OCRPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile
    };
  }

  componentDidMount() {
    enquireScreen(b => {
      this.setState({ isMobile: !!b });
    });
  }
  render() {
    const { intl } = this.props;
    return (
      <>
        <SEO
          title={intl.formatMessage({ id: "seo.title_page23" })}
          pathname="/products/image-recognition"
          description="Sản phẩm Nhận diện hình ảnh của chúng tôi có khả năng xử lý hình ảnh thông minh bằng công nghệ máy học tiên tiến hàng đầu"
          keywords="Nhận diện hình ảnh"
        />
        <Layout>
          <Banner
            id="Banner0_3"
            key="Banner0_3"
            dataSource={Banner03DataSource}
            isMobile={this.state.isMobile}
          />
          {/* <OCR02
            id="OCR02"
            key="OCR02"
            dataSource={OCR02DataSource}
            isMobile={this.state.isMobile}
          /> */}
          <OCR01
            id="OCR01"
            key="OCR01"
            dataSource={OCR01DataSource}
            isMobile={this.state.isMobile}
          />
          {/* <Feature102
            id="Feature102"
            key="Feature102"
            dataSource={Feature102DataSource}
            isMobile={this.state.isMobile}
          /> */}
          <ContactForm
            id="ContactForm"
            key="ContactForm"
            dataSource={ContactFormDataSource}
            isMobile={this.state.isMobile}
          />
        </Layout>
      </>
    );
  }
}

export default injectIntl(OCRPage);
